<template>
    <div>
      <v-layout wrap justify-center pb-8 class="mainfont mainbg2">
        <v-snackbar v-model="showSnackBar" color="black">
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: white">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: white">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
        <vue-element-loading
          :active="appLoading"
          :is-full-screen="true"
          background-color="#FFFFFF"
          color="#283e51"
          spinner="spinner"
        />
        <v-flex xs12 class="mainbg2">
          <v-layout justify-center>
            <v-flex xs12 pl-4>
              <v-layout wrap justify-center>
                <v-flex xs12 pt-8 text-left>
                  <span style="font-size: 25px; font-weight: bolder"
                    >CLERK DASHBOARD</span
                  >
                </v-flex>
                <v-flex xs11 lg12 pt-3>
                  <v-layout wrap justify-start>
                    <v-flex
                      style="cursor: pointer"
                      
                      xs11
                      lg4
                      pt-3
                      pr-4
                    >
                      <v-card elevation="0" class="pa-3" height="120px">
                        <v-layout wrap fill-height justify-center align-center>
                          <v-flex xs8>
                            <v-layout wrap>
                              <v-flex xs12 pt-3>
                                <span style="font-size: 16px; font-weight: bold"
                                  >TOTAL APPLICATIONS</span
                                >
                              </v-flex>
                              <v-flex xs3 pt-1 pl-1>
                                <span style="font-size: 23px; font-weight: bold">
                                  {{ list.totalApplications }}</span
                                >
                              </v-flex>
                              <v-flex xs2 pt-3>
                                <v-icon color="#283e51">mdi-arrow-right</v-icon>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex pt-3 pb-3 xs3 text-right>
                            <v-img
                              height="40px"
                              contain
                              src="../../assets/Images/booking2.png"
                            ></v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex
                      
                      style="cursor: pointer"
                      xs11
                      lg4
                      pr-4
                      pt-4
                      pt-lg-3
                    >
                      <v-card elevation="0" class="pa-3" height="120px">
                        <v-layout wrap fill-height justify-center align-center>
                          <v-flex xs8>
                            <v-layout wrap>
                              <v-flex xs12 pt-3>
                                <span style="font-size: 16px; font-weight: bold"
                                  >PENDING APPLICATIONS</span
                                >
                              </v-flex>
                              <v-flex xs4 lg3 pt-1 pl-1>
                                <span style="font-size: 23px; font-weight: bold">
                                  {{ list.diviPending }}</span
                                >
                              </v-flex>
                              <v-flex xs2 pt-3>
                                <v-icon color="#283e51">mdi-arrow-right</v-icon>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex pt-3 pb-3 xs3 text-right>
                            <v-img
                              height="40px"
                              contain
                              src="../../assets/Images/users1.png"
                            ></v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex
                      style="cursor: pointer"
                      
                      xs11
                      lg4
                      pt-3
                      pr-4
                    >
                      <v-card elevation="0" class="pa-3" height="120px">
                        <v-layout wrap fill-height justify-center align-center>
                          <v-flex xs8>
                            <v-layout wrap>
                              <v-flex xs12 pt-3>
                                <span style="font-size: 16px; font-weight: bold"
                                  >TOTAL NATURE CAMPS</span
                                >
                              </v-flex>
                              <v-flex xs3 pt-1 pl-1>
                                <span style="font-size: 23px; font-weight: bold">
                                  {{ list.totalNatureCamps }}</span
                                >
                              </v-flex>
                              <v-flex xs2 pt-3>
                                <v-icon color="#283e51">mdi-arrow-right</v-icon>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex pt-3 pb-3 xs3 text-right>
                            <v-img
                              height="40px"
                              contain
                              src="../../assets/Images/acco.png"
                            ></v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>

                    <v-flex
                      style="cursor: pointer"
                      
                      xs11
                      lg4
                      pt-3
                      pr-4
                    >
                      <v-card elevation="0" class="pa-3" height="120px">
                        <v-layout wrap fill-height justify-center align-center>
                          <v-flex xs8>
                            <v-layout wrap>
                              <v-flex xs12 pt-3>
                                <span style="font-size: 16px; font-weight: bold"
                                  >APPROVED APPLICATIONS </span
                                >
                              </v-flex>
                              <v-flex xs3 pt-1 pl-1>
                                <span style="font-size: 23px; font-weight: bold">
                                  0</span
                                >
                              </v-flex>
                              <v-flex xs2 pt-3>
                                <v-icon color="#283e51">mdi-arrow-right</v-icon>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex pt-3 pb-3 xs3 text-right>
                            <!-- <v-img
                              height="40px"
                              contain
                              src="../../assets/Images/acco.png"
                            ></v-img> -->
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <!-- <v-flex xs11 lg3 pt-3 pr-4
                    style="cursor: pointer;"
          @click="$router.push('/BookingTomorrow')"
                    >
                      <v-card elevation="0" class="pa-3" height="120px">
                        <v-layout wrap fill-height justify-center align-center>
                          <v-flex xs8>
                            <v-layout wrap>
                              <v-flex xs12 pt-3>
                                <span style="font-size: 16px;font-weight: bold;">CANCELLED APPLICATIONS</span>
                              </v-flex>
                              <v-flex xs3 pt-1 pl-1>
                                <span style="font-size:23px;font-weight: bold;"> {{ list.cancelledApplications }}</span>
                              </v-flex>
                              <v-flex xs2 pt-3>
                                <v-icon color="#283e51">mdi-arrow-right</v-icon>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex pt-3 pb-3 xs3 text-right>
                            <v-img height="40px" contain src="../../assets/Images/advb.png"></v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex> -->
                  </v-layout>
                </v-flex>
                <v-flex pt-7 pr-5 xs11 lg12 v-if="flag" class="mainfont">
                  <div id="barmonth" v-if="barlist.length > 0" class="mainfont">
                    <apexchart
                      height="430"
                      class="mainfont"
                      type="bar"
                      :options="chartOptions"
                      :series="series6"
                    >
                    </apexchart>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import VueApexCharts from "vue-apexcharts";
  import moment from "moment";
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        chartOptions: {
          colors: ["#FFA500", "#14141f", "#14141f"],
          chart: {
            type: "bar",
            background: "#ffff",
  
            id: "barmonth",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: [
                {
                  name: "Object 1",
                  color: "#004d1a",
                },
                {
                  name: "Object 2",
                  color: "#004d1a",
                },
                {
                  name: "Object 3",
                  color: "#14141f",
                },
              ],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: [],
          },
        },
  
        series6: [],
        name: null,
        userProfileTabs: null,
        subtab: null,
        flag: false,
        phone: null,
        price: null,
        category: null,
        description: null,
        productImage: null,
        productType: null,
        productCode: null,
        liveAuctions: [],
        appLoading: false,
        page: 1,
        pages: 0,
        dialog1: false,
        preview: null,
        tab: null,
        items: ["Dashboard", "Auctions"],
        dialog3: false,
        deletedialog: false,
        minAmount: null,
        password: null,
        categoryarray: [],
        formData: new FormData(),
        id: this.$route.query.id,
        productname: null,
        productarray: [],
        list: {},
        currentpage: 1,
        limit: 10,
        msg: null,
        showSnackBar: false,
        curid: [],
        dialog2: false,
      };
    },
    mounted() {
      this.getList();
  
      this.getGraph();
    },
    methods: {
      getGraph() {
        this.appLoading = true;
        this.flag = false;
        axios({
          method: "POST",
          url: "/division/dashboard/graph",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.barlist = response.data.data;
              this.chartOptions.xaxis.categories = [];
              const bookingsData = [];
  
              for (let i = 0; i < this.barlist.length; i++) {
                this.chartOptions.xaxis.categories.push(this.barlist[i].month);
                bookingsData.push(this.barlist[i].bookings);
              }
              this.series6 = [
                {
                  name: "Bookings",
                  data: bookingsData,
                },
              ];
  
              this.flag = true;
            }
            this.appLoading = false;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
  
      getUserType() {
        const uType = localStorage.getItem("uType");
        if (uType === "777") {
          return "admin";
        } else {
          return "division";
        }
      },
      formatTime(time) {
        return moment(time).format("HH:mm");
      },
      goToActiveUsersPage() {
        this.$router.push({
          path: "/ActiveUsersPage",
          query: { status: "Pending", fromDashboard: true },
        });
      },
  
      goToLotsPage() {
        this.$router.push({
          path: "/LotsPage",
          query: { status: "Pending", fromDashboard2: true },
        });
      },
      getList() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/dfo/counts/",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.list = response.data;
            this.appLoading = false;
          })
  
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  .analyticsKey {
    font-family: poppinsregular;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 1px;
  }
  
  .analyticsValue {
    font-family: poppinsmedium;
    font-size: 25px;
    color: #ffffff;
  }
  
  .itemArrow {
    font-family: poppinsregular;
    font-size: 12px;
    color: #ffffff;
  }
  
  .bg1 {
    background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
  }
  
  .bg2 {
    background-image: linear-gradient(to right, #f093fb 0%, #f5576c 100%);
  }
  </style>